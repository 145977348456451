import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const Registration = () => {
  return (
    <LayoutComponent>
      <h2>Registration</h2>

      <div className="testimonials">
        <h5>Note</h5>
        <p>
          Registration on GAINSS is only available for organization
          administrators. Other users cannot sign up directly and must be
          invited by their organization admin to join the platform.
        </p>
      </div>
      <h6>Steps for Organization Admins to Register</h6>
      <ol>
        <li>Visit the GAINSS registration page.</li>
        <li>
          Fill in the form and click on <strong>Create Account.</strong>.
        </li>
        <li>
          A confirmation email will be sent to the provided email address.
        </li>
        <li>
          Verify your email by following the link in the confirmation email.
        </li>
        <li>
          Once the email is verified, you can log in to GAINSS using your
          credentials.
        </li>
      </ol>
    </LayoutComponent>
  )
}

export default Registration
